import React from 'react'

import { useLocation } from '@reach/router';

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, keepParams, ...other }) => {
  const isInternal = /^\/(?!\/)/.test(to)

  const location = useLocation()

  const href = keepParams
    ? `${to}${location.search}`
    : to
  // Use Gatsby Link for internal links, and <a> for others
  if (isInternal) {
    return (
      <a
        href={href}
        className={activeClassName}
        {...other}
      >
        {children}
      </a>
    )
  }
  return (
    <a href={href} {...other}>
      {children}
    </a>
  )
}

Link.defaultProps = {
  keepParams : false
}

export default Link