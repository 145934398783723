import React from "react"

import { AiOutlineCalendar as CalIcon } from '@react-icons/all-files/ai/AiOutlineCalendar'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

import tokens from 'vibemap-constants/design-system/build/json/variables.json'

export const parseDateTime = (datetime) => {
  const date = datetime ? dayjs(datetime) : null
  return date
}

// TODO: Make shared util
const isSameDay = (date1, date2) => {
  const isSame = date1.year() === date2.year() &&
    date1.month() === date2.month() &&
    date1.day() === date2.day();

  return isSame
}


const getOrdinalSuffix = (day, useSupTag = true) => {
  let suffix;
  if (day > 3 && day < 21) {
    suffix = 'th'; // Covers 11th to 19th
  } else {
    switch (day % 10) {
      case 1: suffix = 'st'; break;
      case 2: suffix = 'nd'; break;
      case 3: suffix = 'rd'; break;
      default: suffix = 'th';
    }
  }
  return useSupTag ? `<sup>${suffix}</sup>` : suffix;
}


export const htmlDateTime = (
  datetime,
  endDate = null,
  formatHtml = true,
  showDayOfWeek = false
) => {
  if (datetime) {
    // If ongoing, display today's date if has already started
    const today = dayjs()
    const startDateTime = datetime > today ? datetime : today

    const timeDiff = endDate ? endDate.diff(datetime, 'hours') : null
    const isSameDayEvent = endDate && datetime && isSameDay(datetime, endDate) || timeDiff < 24
    const isSameMonthEvent = endDate && datetime && datetime.month() === endDate.month()

    if (typeof (datetime) == 'string') datetime = parseDateTime(datetime)

    const month = startDateTime.format('MMM')
    const monthEnd = endDate ? endDate.format('MMM') : null
    const monthText = `${month}${monthEnd && !isSameMonthEvent ? ` - ${monthEnd}` : ''}`
    const monthClass = `month ${isSameMonthEvent ? 'same-month' : 'ongoing'}`

    const day = startDateTime.format('D')
    const dayEnd = endDate ? endDate.format('D') : null
    const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`
    const dayEndWithSuffix = `${dayEnd}${getOrdinalSuffix(dayEnd)}`

    const dayText = `${dayWithSuffix}${dayEnd && !isSameDayEvent ? ` - ${dayEndWithSuffix}` : ''}`
    const dayClass = `day ${isSameDayEvent ? 'same-day' : 'ongoing'}`

    const weekday = datetime.format('ddd')
    const hour = datetime.minute() > 0
      ? datetime.format('h:mm')
      : datetime.format('h')
    const period = datetime.format('a')

    const isMidnight = hour === '12' && period === 'am'
    const showHour = hour.length > 0 && isMidnight === false

    const hourFontsize = hour.length > 3
      ? { fontSize: `0.9rem` }
      : { fontSize: `1.6rem` }

    const dateFormated = formatHtml
      ? (
        <span className='datetime'>
          <span className={monthClass}>{monthText}</span>
          {showDayOfWeek && isSameDayEvent ?
            <span className='month'>{weekday}</span>
            : null
          }
          <span className={dayClass} dangerouslySetInnerHTML={{ __html: dayText }} />
          {showHour
            ? (
              <span className='hour' style={hourFontsize}>
                {hour}
                <span className='period' style={hourFontsize}>{period}</span>
              </span>
            )
            : null
          }
        </span>
      )
      : `${showDayOfWeek ? weekday : null} ${month} ${day} ${hour}`

    return dateFormated
  } else {
    return null
  }
}

const style = {
  background: 'white',
  borderRadius: `${tokens.units.base.nano}rem`,
  display: 'flex',
  height: 'fit-content',
  padding: `${tokens.units.base.nano}rem ${tokens.units.base.tiny}rem`,
  position: 'absolute',
  top: '1rem',
  left: '1rem',
  whiteSpace: 'nowrap',
  zIndex: 10
}

const EventBadge = ({
    showIcon = false,
    dateTime,
    end_date,
    ...props
  }) => {

  const date = parseDateTime(dateTime)
  const parsedEndDate = parseDateTime(end_date)

  const isSameDayEvent = date && parsedEndDate && isSameDay(date, parsedEndDate)
  const isSameMonthEvent = date && date?.month() === parsedEndDate?.month()


  const dateFormated = htmlDateTime(date, parsedEndDate, true, !isSameDayEvent)

  return (
    <div className="event-badge pill" style={style}>
      {showIcon
        ? <div style={{ marginRight: `${tokens.units.base.nano}rem` }}>
          <CalIcon />
        </div>
        : null
      }

      {date
        ? dateFormated
        : <span>Event</span>
      }

    </div>
  )
}

export default EventBadge
