import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"

import {
  locationStore,
  pageStore,
} from "state/store-zustand";

import { useIsVisible } from 'react-is-visible'

import LazyBackground from 'components/elements/background-image'

import ReadTimeBadge from "./readTimeBadge"
import EventBadge from "components/shared/eventBadge"
import AppStoreBadge from "components/sections/appStoreCallout/badges"

import Chip from 'components/shared/chip/chip.jsx'
import Link from "components/elements/link"
import CardSkeletons from "components/post/singCard/cardSkeletons"
import CityLabel from 'components/post/cityLabel'
import Truncate from 'react-truncate'

import "./singCard.scss"

const PostSingCard = ({ hideSaved, ...props }) => {
  const ref = useRef()
  const [isShown, setIsShown] = useState(false)
  const isVisible = useIsVisible(ref)

  useEffect(() => {
    if (isVisible) setIsShown(true) // One way; once
  }, [isVisible])

  const currentCity = locationStore((state) => state.cityCurrent)
  const page = pageStore((state) => state.page)

  const postImage = props.gatsbyImageSrc
    ? props.gatsbyImageSrc
    : props.imageSrc

  const renderImageContainer = props => {
    // TODO: handle all this in a common component
    return props.gatsbyImageSrc
      ? (
        <LazyBackground src={postImage} isShowSaved={false}>
          {props.isEvent && <EventBadge dateTime={props.cardState.dateTime} />}
          {props.hasReadTime && <ReadTimeBadge contentToAnalyze={props.contentToAnalyze} />}
        </LazyBackground>
      )
      : (
        <LazyBackground
          src={props.imageSrc}
          style={{
            backgroundSize: `cover`,
            backgroundPosition: `center center`,
          }}
          isShowSaved={false}
        >
          {props.isEvent && <EventBadge dateTime={props.cardState.dateTime} />}
          {props.hasReadTime && <ReadTimeBadge contentToAnalyze={props.contentToAnalyze} />}
        </LazyBackground>
      )
  }

  // TODO: Refactor to separate elements from layout logic
  const renderPostSingCard = (props) => {
    const { category, city } = props

    const vibes = props.vibes
      ? (<div className='vibes'>
        {props.vibes.slice(0, 3).map((vibe, i) => {
          return (
            <Chip
              key={`${vibe.slug}`}
              size={'small'}
              variant={'outlined'}
              text={vibe.name} />
          )
        })}
      </div>)
      : null

    const citySlug = city
      ? city
      : currentCity
        ? currentCity?.slug
        : null


    const isVibeGuide = category == "Vibe Guide"
    const isCitiesPage = page && page.includes('cities')
    const url = (isVibeGuide && isCitiesPage)
      ? `${props.url}${citySlug ? '?cities=' + citySlug : ''}`
      : `${props.url}`

    switch (props.orientation) {
      case "vertical":
        return (
          <Link
            className="sing-card-inner"
            to={url}
            keepParams={true}>
            {renderImageContainer(props)}
            <div className="sing-card-bottom">
              <CityLabel city={currentCity} prefix={props.category} />

              <h5 className="title">
                <Truncate lines={2} ellipsis="...">
                  <span dangerouslySetInnerHTML={{
                    __html: props.title
                  }} />
                </Truncate>
                {/* <span dangerouslySetInnerHTML={{ __html: props.title.length > 50 ? `${props.title.substring(0, 50)}&hellip;` : `${props.title.substring(0, 50)}` }}
                /> */}
              </h5>
              {props.showAppStoreBadges &&
                <AppStoreBadge />
              }
              {props.hasMapLink && (
                <a className="view-in-map" href="#" target="_blank">
                  View details
                </a>
              )}
              {vibes}
            </div>
          </Link>
        )

      case "horizontal":
        return (
          <Link className="sing-card-inner" to={props.url}>
            <div className="columns is-flex-tablet is-gapless">
              <div className={`column ${props.type == 'badge' ? 'is-4-tablet' : 'is-6-tablet'} image is-flex-tablet is-12-mobile`}>
                {renderImageContainer(props)}
              </div>
              <div className="column is-6-tablet text is-flex-tablet is-12-mobile">
                <div className="inner-column">
                  <span className="category">
                    {props.category}
                    {props.city ? ` —  ${props.city}` : null}
                  </span>
                  <h5 className="title">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: props.title,
                      }}
                    />
                  </h5>
                  {props.description &&
                    <p dangerouslySetInnerHTML={{
                      __html: props.description,
                    }}
                      style={{
                        maxHeight: '10rem',
                        overflow: 'hidden',
                      }}
                    />
                  }
                  {props.showAppStoreBadges &&
                    <div className='container'>
                      <AppStoreBadge />
                    </div>
                  }
                  {props.hasMapLink && (
                    <a className="view-in-map" href="#" target="_blank">
                      View in the map
                    </a>
                  )}
                  {vibes}
                </div>
              </div>
            </div>
          </Link>
        )
      default:
        return null
    }
  }

  return (
    <article ref={ref} className={`sing-card ${props.type} o-${props.orientation} s-${props.style} ${props.size}`}>
      {isShown
        ? renderPostSingCard(props)
        : <CardSkeletons num={1} horizontal={false} />}
    </article>
  )
}

PostSingCard.propTypes = {
  orientation: PropTypes.string.isRequired,
  size: PropTypes.string,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  gatsbyImageSrc: PropTypes.object,
  imageSrc: PropTypes.string,
  category: PropTypes.string,
  city: PropTypes.string,
  content: PropTypes.string,
  hasMapLink: PropTypes.bool,
  hasReadTime: PropTypes.bool,
  isEvent: PropTypes.bool,
}

PostSingCard.defaultProps = {
  size: 'large'
}

export default PostSingCard